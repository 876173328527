/** @jsx jsx */
import { useStaticQuery, graphql, Link } from "gatsby"
import { jsx } from "theme-ui"
import Img from "gatsby-image"

const NavItem = props => (
  <li>
    <Link
      {...props}
      activeClassName="active"
      sx={{
        color: "text",
        fontFamily: "body",
        textDecoration: "none",
        "&:hover": {
          color: "secondary",
        },
        "&.active": {
          color: "orange",
        },
        height: "100%",
      }}
    />
  </li>
)

export default () => {
  const data = useStaticQuery(graphql`
      query {
          file(relativePath: { eq: "Logo.png" }) {
              childImageSharp {
                  fluid(maxWidth: 300) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
      }
  `)

  return (
    <header>
      <Link to="/"><Img fluid={data.file.childImageSharp.fluid} alt="Crow & Apricot logo"
           sx={{ margin: "32px auto", width: "200px" }} loading="eager"/></Link>
      <nav>
        <ul
          sx={{
            display: "flex",
            margin: 0,
            padding: 0,
            height: "48px",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "muted",
            "& li": {
              marginLeft: "1rem",
              listStyleType: "none",
            },
          }}
        >
          <NavItem to="/gallery">Gallery</NavItem>
          <NavItem to="/styles">Styles</NavItem>
          <NavItem to="/about">About</NavItem>
          <NavItem to="/buy">Buy Art</NavItem>
          <NavItem to="/contact">Contact</NavItem>
          <NavItem to="/blog">Blog</NavItem>
        </ul>
      </nav>
    </header>
  )
}
