/** @jsx jsx */
import { jsx } from "theme-ui"

export const centered = props => (
  <div
    {...props}
    sx={{
      display: "flex",
      flexDirection: "column",
      maxWidth: "container",
      mx: "auto",
      padding: 2,
      justifyContent: "center",
      textAlign: "center"
    }}
  />)

export const bioText = props => (
  <div
    {...props}
    sx={{
      maxWidth: "container",
      mx: "auto",
      padding: 4
    }}
  />)

  export const imgRow = props => (
    <div
      {...props}
      sx={{
        maxWidth: "container",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-evenly"
      }}
    />)